<template>
    <div style="width: 100%">
        <div class="container">
            <!--  :class="{ac: about, 'hs': history, 'cc': culture, 'vc': lives}" -->
            <page-header-image />
            <div style="margin-top: 100px"></div>
            <div class="contentContainer">
                <div class="slideBar">
                    <div class="about" :class="{active: about}" @click="showAbout">
                        About us
                        <img class="vector" src="..\static\img\Vector.png" alt="" :class="{activeVector: about}" @click="showAbout">
                    </div>
                    <div class="history" :class="{active: history}" @click="showHistory">
                        History
                        <img class="vector" src="..\static\img\Vector.png" alt="" :class="{activeVector: history}" @click="showHistory">
                    </div>
                    <div class="culture" :class="{active: culture}" @click="showCulture">
                        Enterprise Culture
                        <img class="vector" src="..\static\img\Vector.png" alt="" :class="{activeVector: culture}" @click="showCulture">
                    </div>
                    <div class="lives" :class="{active: lives}" @click="showLives">
                        Workshop
                        <img class="vector" src="..\static\img\Vector.png" alt="" :class="{activeVector: lives}" @click="showLives">
                    </div>
                </div>
                <div class="content">
                    <div class="aboutContainer" :class="{activeContent: about}" @click="showAbout">
                        <img class="contentHeadImg" src="..\static\img\showImg2.png" alt="">
                        <p style="text-indent:2em">宝德仕电玩制造（深圳）有限公司在2012年成立于深圳市坪山区。现在是一间基于精益生产制造模式的港资中型OEM/ODM企业。主要专注于依据客户要求生产和制造高端电子消费品、硅胶类电子产品。</p>
                        <br>
                        <p>Baodeshi (Shenzhen) Ltd. was established in 2012 in Pingshan District, Shenzhen.
                            It is a Hong Kong-funded OEM/ODM company now strong in Lean Manufacturing. Mainly
                            focused on manufacture of high-end consumer electronics and silicone products to meet our customer requirements.</p>
                        <br>
                        <p style="text-indent:2em">现佣有精装修厂房8315平方米。其中设有高级防尘全静电装配车间2500平方米，生产设备主要有高精密度的纯电注塑机、OCC光学检测器、气密性检测器、RTV硅胶灌注机等先进制造设备。公司致力于高精密的自动化制造、生产环保级电子消费品。</p>
                        <br>
                        <p> The plant occupies 8315 square meters and consists of 2500 square meters anti-static assembly lines with full air conditioning.
                            In regards to production equipment: high precision electric injection moulding machines with assist of robotic arms, OCC optical detectors,
                            air leak detector, RTV silicone perfusion machine and other advanced manufacturing equipment. We are committed to implementing high-precision
                             automated manufacturing and producing eco-friendly consumer electronic products.</p>
                    </div>
                    <div class="historyContainer" :class="{activeContent: history}" @click="showHistory">
                        <img class="contentHeadImg" src="..\static\img\showImg2.png" alt="">
                        <p style="text-indent:2em">宝德仕电玩制造（深圳）有限公司于1985年成立于深圳市坪山区﹐是在陆最早期的三来一补企业。于2012年改制为外资独资企业，现是一间从事自有专利品牌设计及制造高新电子科技及人体健康/医疗类产品为主，
                            电子益智教育类产品为辅的国家高新技术认证企业﹐产品销往国内及欧美、其它发达国家和地区﹐是香港著名的原始设计和设备制造公司之一。</p>
                        <br>
                        <p style="text-indent:2em">Baodeshi (Shenzhen) Ltd. was established in 1985 in Pingshan District, Shenzhen. It is the earliest three-to-one enterprise. In 2012,
                             it is a national high-tech certified enterprise, a national high-tech certified enterprise, an electronic puzzle education product
                             , an electronic puzzle education product. It is sold to domestic and Europe and America, other
                              developed countries and regions, is one of Hong Kong's famous original design and equipment manufacturing companies.</p>
                    </div>
                    <div class="cultureContainer" :class="{activeContent: culture}" @click="showCulture">
                        <img class="contentHeadImg" src="..\static\img\showImg2.png" alt="">
                        <p>1）确保我们的质量控制</p>
                        <p>1）Ensure our quality control</p>
                        <br>
                        <p>2）提高效率和有效性</p>
                        <p>2）Enhance efficiency and effectiveness</p>
                        <br>
                        <p>3）投资新技术，满足客户的需求</p>
                        <p>3）Investing in new technology to meet our customer’s needs</p>
                        <br>
                        <p>4）不断发展员工的专业知识、专业精神和诚信</p>
                        <p>4）Continually developing the expertise, professionalism and integrity of our people</p>
                        <br>
                        <p>5）公司愿景是生产高质量产品和满足客户各种需求</p>
                        <p> 5）Our vision is to develop and produce high-quality products and meet the needs of customers</p>
                        <br>
                        <p>6）我们致力于成为世界一流的高端电子消费品生产制造商</p>
                        <p>6）We are committed to being a world-class electronic, silicone manufacturer of high-end consumer products</p>
                        <br>
                    </div>
                    <div class="livesContainer" :class="{activeContent: lives}" @click="showLives">
                        <div class="livesItem">
                            <img src="..\static\img\advantage1.jpg" alt="">
                            <p>1）全自动化无人纯电注塑机车间；<br> Fully Automated Injection Moulding Workshop</p>
                        </div>
                        <div class="livesItem">
                            <img src="..\static\img\advantage2.jpg" alt="">
                            <p>2）制造高精密配件的成型如：光学镜片，高硬零配件等；<br>
                            High Precision Injection Moulding Machines Particular <br>
                            For Optical Lens And Precision Parts etc.</p>
                        </div>
                        <div class="livesItem">
                            <img src="..\static\img\advantage3.jpg" alt="">
                            <p>3）装配车间：防静电防尘精益生产制造，LEAN CELL小单元生产模式；<br>
                            Anti-Static Assembly Workshop With Full Air Conditioning <br>
                            Implementing Lean Manufacturing Cells</p>
                        </div>
                        <div class="livesItem">
                            <img src="..\static\img\advantage4.jpg" alt="">
                            <p>4）包装车间：5S精益车间，电脑纪录包装出货；<br>
                            Packaging Workshop 5S Lean Cells Setup, <br>
                            Scanning Bar Codes Before Packing</p>
                        </div>
                        <div class="livesItem">
                            <img src="..\static\img\live5.png" alt="">
                            <p>5）硅胶成型和UV表面处理车间。<br>
                            RTV Silicone Workshop With Full Automation <br>
                            UV Surface Treatment Machine </p>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 200px"></div>
        </div>
        <!-- <float-box /> -->
        <!-- <index-footer /> -->
    </div>
</template>
<script>
import FloatBox from '../components/floatBox.vue';
import IndexFooter from '../components/IndexFooter.vue';
import PageHeaderImage from '../components/PageHeaderImage.vue';

export default {
  components: { PageHeaderImage, FloatBox, IndexFooter },
  data() {
    return {
      about: true,
      history: false,
      culture: false,
      lives: false,
    };
  },
  methods: {
    showAbout() {
      this.about = true;
      this.history = false;
      this.culture = false;
      this.lives = false;
    },
    showHistory() {
      this.about = false;
      this.history = true;
      this.culture = false;
      this.lives = false;
    },
    showCulture() {
      this.about = false;
      this.history = false;
      this.culture = true;
      this.lives = false;
    },
    showLives() {
      this.about = false;
      this.history = false;
      this.culture = false;
      this.lives = true;
    },
  },
};
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }

    .contentContainer{
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-column-gap: 70px;
        position: relative;
        left: 5%;
        width: 90%;
    }

    .slideBar{
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 476.25px;
        width: 100%;
    }

    .about, .history, .culture, .lives{
        position: relative;
        width: 100%;
        height: 80px;
        background: #1F89EB;
        margin-top: 10px;
        font-family: PingFang SC;
        font-style: normal;
        font-size: 32px;
        line-height: 80px;
        color: #FFFFFF;
        padding-left: 30px;
        text-align: start;
        transition: all 0.5s;
        cursor: pointer;
    }

    .active{
        background: #041D72;
        transition: all 0.5s;
        height: 206.25px;
    }

    .vector{
        position: absolute;
        right: 30px;
        top: 24px;
        height: 32px;
        transition: all 0.5s;
    }

    .activeVector{
        transform: rotate(90deg);
    }

    .content{
        position: relative;
        width: 100%;
        transition: all 0.5s;
    }

    .aboutContainer, .historyContainer, .cultureContainer, .livesContainer{
        width: inherit;
        display: none;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: justify;
        color: #0E0E0E;
        opacity: 0;
        transition: all 0.5s;
        margin-top: 10px;
    }

    .contentHeadImg{
        width: 100%;
        height: 268.125px;
        margin-bottom: 30px;
    }

    .activeContent{
        opacity: 1;
        transition: all 0.5s;
        display: inline-block;
    }

    .livesItem{
        width: 100%;
        margin-bottom: 90px;
    }

    .livesItem > img{
        width: 100%;
        height: 250px;
    }

    .livesItem > p{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #0E0E0E;
        text-align: center;
    }

    @media screen and (max-width: 1050px) {
        .contentContainer{
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
        }

        .slideBar{
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: 80px;
            width: 100%;
            margin-bottom: 30px;
        }

        .about, .history, .culture, .lives{
            margin-top: 0;
            margin-right: 10px;
            padding: 0;
            text-align: center;
        }

        .vector{
            display: none;
        }

        .active{
            background: #041D72;
            transition: all 0.5s;
            height: 80px;
        }
    }

    @media screen and (max-width: 750px) {
        .about, .history, .culture, .lives{
            font-size: 16px;
            height: 60px;
            line-height: 60px;
        }

        .slideBar{
            height: 60px;
            margin-bottom: 15px;
        }
    }
    @media screen and (min-width: 1200px){
        .livesItem > img {
                width: 100%;
                height: 400px;
                z-index: -1;
            }
    }
</style>
